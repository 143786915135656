<template>
  <div class="container">
    <div class="search">
      <div class="search-first">
        <div class="search-item">
          <div class="search-item-name">客户昵称：</div>
          <el-input
            placeholder="请输入客户昵称"
            prefix-icon="el-icon-search"
            v-model="search.nickName"
          >
          </el-input>
        </div>
        <div class="search-item">
          <div class="search-item-name">手机号码：</div>
          <el-input
            placeholder="请输入手机号码"
            prefix-icon="el-icon-search"
            v-model="search.phone"
          >
          </el-input>
        </div>
        <div class="search-item">
          <div class="search-item-name">申请时间：</div>
          <el-date-picker
            clearable
            v-model="search.range"
            type="daterange"
            range-separator="至"
            start-placeholder="开始日期"
            end-placeholder="结束日期"
          >
          </el-date-picker>
        </div>
        <div>
          <el-button type="primary" plain @click="reset">重置</el-button>
          <el-button type="primary" icon="el-icon-search" @click="handleSearch"
            >搜索</el-button
          >
        </div>
      </div>
      <div class="search-second">
        <div class="search-item">
          <div class="search-item-name">金额状态：</div>
          <el-select
            v-model="search.status"
            placeholder="请选择活跃状态"
            clearable
          >
            <el-option label="待确认" value="ACTIVE"> </el-option>
            <el-option label="已处理" value="DONE"> </el-option>
            <el-option label="已驳回" value="REJECT"> </el-option>
          </el-select>
        </div>
      </div>
    </div>

    <div class="content">
      <el-table ref="multipleTable" :data="members" style="width: 100%">
        <el-table-column label="提现金额">
          <template slot-scope="scope">
            <span>￥{{ _pennyToYuan(scope.row.money) }}</span>
          </template>
        </el-table-column>
        <el-table-column prop="nickName" label="客户昵称"> </el-table-column>
        <el-table-column label="头像">
          <template slot-scope="scope">
            <img
              :src="scope.row.imgUrl"
              style="widht: 40px; height: 40px; border-radius: 100%;"
            />
          </template>
        </el-table-column>
        <el-table-column label="状态">
          <template slot-scope="scope">
            <span v-if="scope.row.status === 'ACTIVE'">待确认</span>
            <span v-if="scope.row.status === 'DONE'">已处理</span>
            <span v-if="scope.row.status === 'REJECT'">已驳回</span>
          </template>
        </el-table-column>
        <el-table-column label="钱包余额">
          <template slot-scope="scope">
            <span>￥{{ _pennyToYuan(scope.row.balance) }}</span>
          </template>
        </el-table-column>
        <el-table-column prop="date" label="申请时间"> </el-table-column>
        <el-table-column prop="reason" label="驳回原因"></el-table-column>
        <el-table-column
          align="center"
          prop="operation"
          min-width="100"
          label="操作"
        >
          <template slot-scope="scope">
            <el-button
              v-if="scope.row.status === 'ACTIVE'"
              type="text"
              @click="agree(scope.row)"
              >同意</el-button
            >
            <el-button
              v-if="scope.row.status === 'ACTIVE'"
              type="text"
              @click="refuse(scope.row)"
              >拒绝</el-button
            >
          </template>
        </el-table-column>
      </el-table>
    </div>

    <div class="pagination">
      <el-pagination
        @size-change="handleSizeChange"
        @current-change="handleCurrentChange"
        :current-page="currentPage"
        :page-sizes="pageOptions"
        :page-size="10"
        layout="total, sizes, prev, pager, next, jumper"
        :total="total"
      >
      </el-pagination>
    </div>
    <!-- 拒绝填写里有弹框 -->
    <el-dialog
      title="拒绝原因"
      :visible.sync="showRefuseDialog"
      width="40%"
      append-to-body
    >
      <el-input
        type="textarea"
        :rows="4"
        placeholder="请填写拒绝原因"
        v-model="refuseReason"
      ></el-input>
      <div class="sure-refuse">
        <el-button type="primary" size="medium" @click="sureRefuse"
          >确定</el-button
        >
      </div>
    </el-dialog>
  </div>
</template>

<script>
import * as R from 'ramda'
import { pageOptions } from '@/utils/config'
import * as withdrawService from '@/api/withdraw'
import moment from 'moment'
// import EditContent from './edit'

const init = {
  nickName: null,
  phone: null,
  status: null,
  range: null
}

export default {
  // components: { EditContent },
  data () {
    return {
      search: R.clone(init),
      type: 'create',
      members: [],
      pageOptions: pageOptions,
      total: 10,
      pageSize: 10,
      currentPage: 1,
      showEdit: false,
      selectData: null,
      nowId: '', // 当前处理的id
      showRefuseDialog: false, // 是否显示拒绝弹框
      refuseReason: '' // 拒绝原因
    }
  },
  created () {
    this.fetchData()
  },
  methods: {
    fmtDate (date) {
      return moment(date).format('YYYY-MM-DD')
    },
    async fetchData (page = 1, size = 10, search = null) {
      try {
        const query = search
          ? {
            nickName: search.nickName,
            phone: search.phone,
            startDate: search.range ? this.fmtDate(search.range[0]) : null,
            endDate: search.range ? this.fmtDate(search.range[1]) : null,
            status: search.status,
            pageNo: page - 1,
            pageSize: size
          }
          : {
            pageNo: page - 1,
            pageSize: size
          }
        const res = await withdrawService.list(query)
        this.members = res.records
        this.total = res.total
      } catch (err) {
        console.log(err)
      }
    },
    handleSearch () {
      this.fetchData(1, this.pageSize, this.search)
    },
    async agree (data) {
      try {
        await withdrawService.agreeWithdraw(data.id)
        this.$notify.success({ title: '同意成功！' })
        this.fetchData(this.currentPage, this.pageSize, this.search)
      } catch (error) {
        const message = error.title || '同意失败！'
        this.$notify.error({ title: message })
      }
    },
    refuse (data) {
      this.nowId = data.id
      this.showRefuseDialog = true
    },
    async sureRefuse () {
      try {
        await withdrawService.refuseWithdraw(this.nowId, this.refuseReason)
        this.$notify.success({ title: '拒绝成功！' })
        this.showRefuseDialog = false
        this.fetchData(this.currentPage, this.pageSize, this.search)
      } catch (error) {
        const message = error.title || '拒绝失败！'
        this.$notify.error({ title: message })
      }
    },
    handleSizeChange (val) {
      this.pageSize = val
      this.fetchData(this.currentPage, val, this.search)
    },
    handleCurrentChange (val) {
      this.currentPage = val
      this.fetchData(val, this.pageSize, this.search)
    },
    reset () {
      this.search = R.clone(init)
      this.fetchData(this.currentPage, this.pageSize, this.search)
    }
  }
}
</script>

<style scoped lang="scss">
.container {
  width: 100%;
  text-align: left;
}

.content {
  margin-top: 10px;
  background: #ffffff;
  padding: 10px;
}
.pagination {
  margin-top: 30px;
  text-align: center;
}

.search {
  padding: 30px;
  margin: 10px auto;
  width: 100%;
  background: white;

  &-first {
    display: grid;
    grid-template-columns: 1fr 1fr 1fr 2fr;
    grid-column-gap: 20px;
    padding-bottom: 20px;
  }
  &-second {
    border-top: 1px solid #f4f7fc;
    padding-top: 20px;
    display: grid;
    grid-template-columns: 2fr 2fr 3fr;
    grid-column-gap: 20px;
  }
  &-item {
    display: grid;
    grid-template-columns: 80px auto;
    align-items: center;
  }
}
.sure-refuse {
  width: 100%;
  margin-top: 10px;
  text-align: right;
}
</style>
