import axios from './axios'

export const list = params => axios.get('/management/withdraw', { params })
// export const update = (id, form) => axios.put(`/management/specs/${id}`, form)
// export const deleteById = id => axios.delete(`/management/specs/${id}`)
// export const create = form => axios.post('/management/specs', form)
// 同意申请
export const agreeWithdraw = id => axios.post(`/management/withdraw/agree/${id}`)
// 拒绝申请
export const refuseWithdraw = (id, reason) => axios.post(`/management/withdraw/reject/${id}?reason=${reason}`)
